
import './App.css';
import Main from '../src/components/Main.jsx'

function App() {
  return (
    <div className="App">
      <Main></Main>
      
    </div>
  );
}

export default App;
